// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery

require("trix");
require("@rails/actiontext");

import "./src/application.scss";
import "./src/login.scss.erb";
import "./src/calendar.css";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

window.jQuery = $;
window.$ = $;
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

import "@fortawesome/fontawesome-free/css/all.css";
import "chartkick/chart.js";

$(document).on("click", function () {
  $(".collapse").collapse("hide");
});

require("jquery");
require("easy-autocomplete");
